import * as React from "react";
import "../styles/global.css";
import { Link } from "gatsby";
import NavItem from "./navitem";
import Logo from "../../static/assets/logo3.png"

const Navbar = () => {
  return (
    <header className="w-full bg-white shadow sticky top-0 z-10">

      <div className="w-full max-w-5xl mx-auto align-middle flex">

        <div className="flex justify-start">
          <Link to="/">
            <img className="transform scale-75" src={Logo} alt="Logo"/>
          </Link>
        </div>

        <div className="flex-1 justify-end flex items-center w-auto">
          <nav>
            <ul className="flex items-center justify-between text-base text-gray-500 pt-0 text-xl">
              <NavItem link="" linkLabel="Home"></NavItem>
              <NavItem link="blog" linkLabel="Blog"></NavItem>
              <NavItem link="" linkLabel="Contact"></NavItem>
            </ul>
          </nav>
        </div>
      </div>

    </header>
  )
}

export default Navbar;