import * as React from "react";
import "../styles/global.css";

import Navbar from "./navbar";
import Footer from "./footer";

const Layout = (props) => {
  return(
    <div className="flex flex-col min-h-screen">
      <Navbar/>
      <main className="flex-grow">
        <div className="max-w-5xl mx-auto">
          {props.children}
        </div>
      </main>
      <Footer/>
    </div>
  )
}

export default Layout;