import * as React from "react";
import "../styles/global.css";
import {Link} from "gatsby";

const NavItem = (props) => {
  return(
    <li className="transition duration-300 ease-in-out transform hover:scale-125">
      <Link to={`/${props.link}`} className="p-4 border-b-2 border-transparent hover:text-gray-700">{props.linkLabel}</Link>
    </li>
  )
}

export default NavItem;